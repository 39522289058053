<script setup>
  import { provide } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import AppToast from '@/components/AppToast.vue'
  import { useApi } from '@/functions/api'
  import { useStore } from '@/store'
  import { useToastStore } from '@/store/toast'

  const route = useRoute()
  const router = useRouter()
  const store = useStore()
  const toast = useToastStore()

  const api = useApi()

  provide('route', route)
  provide('router', router)
  provide('store', store)
  provide('api', api)
  provide('toast', toast.add)

  api.GET('/sanctum/csrf-cookie')
</script>

<template>
  <router-view />
  <app-toast />
</template>
