<script setup lang="ts">
  import { computed } from 'vue'
  import { fmt } from '@/functions/formatters'

  defineOptions({
    name: 'FormInput',
    inheritAttrs: false,
  })

  const props = defineProps<{
    id?: string,
    classLabel?: string | Record<string, string>,
    error?: string | string[],
    type?: string,
    hideMaxLength?: boolean,
    rows?: number,
    maxlength?: number,
  }>()

  const value = defineModel<string | number>({ required: true })

  const hasError = computed(() => props.error)
</script>

<template>
  <div
    class="relative my-2 py-1"
    :class="{'has-error': hasError}">
    <label
      :for="id ?? ''"
      class="text-xs text-gray-500"
      :class="classLabel">
      {{ $attrs.placeholder }}
    </label>
    <div class="flex items-end">
      <textarea
        v-if="type === 'textarea'"
        v-model="value"
        class="form-input"
        :rows="rows ?? 3"
        :class="{'!pr-8': maxlength && !hideMaxLength}"
        v-bind="$attrs"></textarea>
      <input
        v-else
        v-model="value"
        :type="type"
        class="form-input"
        :class="{'!pr-8': $attrs.maxlength && !hideMaxLength}"
        v-bind="$attrs">
      <div class="absolute right-0">
        <span
          v-if="maxlength && !hideMaxLength"
          class="bg-white px-1 text-3xs">
          {{ fmt.number(maxlength - value.toString().length) }}
        </span>
      </div>
    </div>
    <g-error-msg :message="error" />
  </div>
</template>

<style lang="postcss" scoped>
.has-error {
  input, textarea {
    @apply border-b border-b-red-400;
  }
  label {
    @apply text-red-400;
  }
}
</style>

<style lang="postcss">
.form-input {
  @apply w-full py-1 text-sm font-medium placeholder-transparent focus:outline-none;
  @apply border-b border-gray-300 text-gray-800;
}
</style>
