import { capitalCase, kebabCase } from 'change-case'
import byte from './byte'
import {
  date,
  dateDiffHuman,
  dateSchedule,
  dateScheduleExtended,
} from './date'
import duration from './duration'
import { decimal, number } from './number'

export const fmt = {
  number,
  decimal,
  date,
  dateDiffHuman,
  dateSchedule,
  dateScheduleExtended,
  kebab: kebabCase,
  title: capitalCase,
  duration,
  byte,
}
