export default {
  path: '/review',
  component: () => import('@/views/Appointment/Review/Index.vue'),
  children: [
    {
      path: 'create/:id',
      name: 'review create',
      props: true,
      component: () => import('@/views/Appointment/Review/Create/Index.vue'),
    },
    {
      path: ':id',
      name: 'review show',
      props: true,
      component: () => import('@/views/Appointment/Review/Show/Index.vue'),
    },
  ],
}
