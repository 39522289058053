<script setup lang="ts">
  import { type RouteLocationRaw } from 'vue-router'
  import { useRouter } from 'vue-router'

  defineOptions({ name: 'GTitle' })
  const router = useRouter()

  defineProps<{ title?: string, back?: RouteLocationRaw }>()
</script>

<template>
  <div class="mb-4 flex items-center">
    <router-link
      v-if="back"
      :to="back"
      class="mr-2 p-2 lg:hidden">
      <fa-icon icon="i-far-chevron-left" />
    </router-link>
    <button
      v-else
      class="mr-2 p-2 lg:hidden"
      @click="router.go(-1)">
      <fa-icon icon="i-far-chevron-left" />
    </button>
    <div class="inline truncate text-lg font-medium">
      <slot>{{ title }}</slot>
    </div>
  </div>
</template>
