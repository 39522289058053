import RouterView from '@/functions/RenderEmptyRouterView'

export default {
  path: '/company',
  component: RouterView,
  children: [
    {
      path: '',
      name: 'company',
      component: () => import('@/views/Company/Index.vue'),
    },
    {
      path: 'create',
      name: 'company create',
      component: () => import('@/views/Company/CreateEdit.vue'),
    },
    {
      path: 'edit',
      name: 'company edit',
      component: () => import('@/views/Company/CreateEdit.vue'),
    },
    {
      path: 'members',
      name: 'members',
      component: () => import('@/views/User/Index.vue'),
    },
    {
      path: 'member/:id/:name?',
      name: 'member show',
      props: true,
      component: () => import('@/views/User/Show/PageDetail.vue'),
    },
  ],
}
