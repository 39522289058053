import * as Sentry from '@sentry/vue'
import { type App } from 'vue'
import { type Router } from 'vue-router'

export default {
  install (app: App<Element>, router: Router) {
    // dont run in development
    if (import.meta.env.DEV) {
      return
    }

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.1,
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/api.imagowellness.co.nz/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

      // Christ's addition
      release: __APP_VERSION__,
      ignoreErrors: [
        // axios server common https statuses
        'Request failed with status code 401',
        'Request failed with status code 403',
        'Request failed with status code 404',
        'Request failed with status code 419',
        'Request failed with status code 422',
        'Request failed with status code 429',
        'Request failed with status code 500',
        'Request failed with status code 502',
        'Request failed with status code 503',
        '/messaging/unsupported-browser/', // Firebase error
        // errors list source: https://gist.github.com/impressiver/5092952
        // stackoverflow says this can be safely ignored: https://stackoverflow.com/a/50387233/3671954
        'ResizeObserver loop limit exceeded',
        '/privateSpecialRepair/', // weird vivo browser error
        // axios server common https statuses
        // don't know what caused this error and why is this reported to sentry
        'Failed to fetch dynamically imported module',
        'Unable to preload CSS',
        'Request aborted',
        'Network Error',
      ],
    })
  },
}
