<script setup lang="ts">
  import { useModal } from '@/components/modal'
  import AppModalBase from '@/components/modal/app-modal-base.vue'
  import AppModalBtnClose from '@/components/modal/app-modal-btn-close.vue'
  import AppModalTitle from '@/components/modal/app-modal-title.vue'

  defineProps<{
    title?: string,
    maxWidth?: number,
  }>()

  const emit = defineEmits<{
    (e: 'closed'): void,
  }>()

  const { isOpen, open, close } = useModal()

  const afterLeave = () => {
    emit('closed')
  }

  defineExpose({ open, close })
</script>

<template>
  <app-modal-base v-model="isOpen">
    <transition
      enter-from-class="translate-y-8 opacity-0"
      leave-to-class="translate-y-8 opacity-0"
      @after-leave="afterLeave()">
      <!-- Full-screen container to center the panel -->
      <div
        v-if="isOpen"
        class="fixed inset-0 z-40 flex items-center p-4 transition-all duration-300 ease">
        <!-- The actual dialog panel -->
        <div
          v-click-outside="close"
          :style="{maxWidth: (maxWidth ?? 32) + 'rem'}"
          class="relative mx-auto w-full bg-white p-6 align-middle shadow-xl">
          <app-modal-title>{{ title }}</app-modal-title>
          <app-modal-btn-close
            class=""
            @click="isOpen = false" />

          <slot></slot>
        </div>
      </div>
    </transition>
  </app-modal-base>
</template>

<style lang="postcss" scoped>
  :deep() {
    .modal-content {
      @apply max-h-[calc(100vh-120px)] overflow-auto;

      /* prevent focus ring clipped by overflow-auto */
      @apply pl-2 pr-3 py-2 -my-2 -ml-2 -mr-4;
    }
    .modal-footer {
      @apply mt-4 flex items-center justify-end pt-2 space-x-4;
    }
  }
</style>
