<script setup lang="ts">
  const emit = defineEmits<{
    (e: 'click'): void,
  }>()
</script>

<template>
  <div class="absolute right-4 top-5 z-1 text-gray-800">
    <button
      class="px-2 py-1"
      @click="emit('click')">
      <fa-icon icon="i-far-xmark" />
    </button>
  </div>
</template>
