export default {
  path: '/',
  component: () => import('@/layouts/LayoutAuth.vue'),
  children: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Auth/Login.vue'),
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Auth/Register.vue'),
    },
    {
      path: '/forgot',
      name: 'forgot password',
      component: () => import('@/views/Auth/ForgotPassword.vue'),
    },
    {
      path: '/password/reset',
      name: 'reset password',
      component: () => import('@/views/Auth/ResetPassword.vue'),
    },
  ],
}
